import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';

// const unauthorizedCode = [400, 401, 403];
const unauthorizedCode = [401];

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 120000
});

// Config
const TOKEN_PAYLOAD_KEY = 'Authorization';

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: 'Error'
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  (error) => {
    let notificationParam = {
      message: 'Something Wrong happened!'
    };

    // Remove token and redirect
    if (unauthorizedCode.includes(error?.response?.status)) {
      notificationParam.message = 'Authentication Fail';
      notificationParam.description = 'Please login again';
      localStorage.removeItem(AUTH_TOKEN);

      store.dispatch(signOutSuccess());
    }

    if (error.response?.data?.message) {
      notificationParam.message = error.response.data.message;
    }
    if (error?.response?.status === 404) {
      return Promise.reject(error);
    }

    // if (error?.response?.status === 406) {
    //   notificationParam.message =
    //     'Username, email or contact already associated with another account';
    // }

    // if (error?.response?.status === 500) {
    //   notificationParam.message = 'Internal Server Error';
    // }

    // if (error?.response?.status === 508) {
    //   notificationParam.message = 'Time Out';
    // }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
