import {
  BillsKey,
  ClientExecutiveKey,
  InventoryKey,
  InvoiceKey,
  ThirdPartyEmployeeKey
} from './RoutesKeys';

export const AUTH_TOKEN = 'auth_token';
export const AUTH_TYPE = 'auth_type';
export const AUTH_ROLES = 'auth_roles';
export const AUTH_ID = 'auth_id';
export const AUTH_NAME = 'auth_name';
export const AUTH_ACCOUNT_DETAILS = 'account_details';
export const AUTH_GST_DETAILS = 'gst_details';
export const AUTH_USER = 'auth_user';
export const AUTH_USER_DETAILS = 'auth_user_details';
export const AUTH_VENDOR_CATEGORIES = 'auth_vendor_categories';
export const ADMIN_TYPE = 'ADMIN';
export const VENDOR_TYPE = 'VENDOR';
export const EMPLOYEE_TYPE = 'EMPLOYEE';
export const CUSTOMER_TYPE = 'CUSTOMER';
export const CLIENT_TYPE = 'CLIENT';
export const THIRD_PARTY_TYPE = 'THIRD PARTY';
export const THIRD_PARTY_EMPLOYEE_TYPE = 'THIRD PARTY EMPLOYEE';
export const CLIENT_EXECUTIVE_TYPE = 'CLIENT EXECUTIVE';
export const VENDOR_CATEGORIES = 'vendorCategories';
export const AUTHENTICATED_CUSTOMER_ENTRIES = [InventoryKey, InvoiceKey];
export const AUTHENTICATED_CLIENT_ENTRIES = [
  InventoryKey,
  InvoiceKey,
  ClientExecutiveKey,
  BillsKey
];
export const AUTHENTICATED_CLIENT_EXECUTIVE_ENTRIES = [InventoryKey, BillsKey];
export const AUTHENTICATED_THIRD_PARTY_ENTRIES = [
  ThirdPartyEmployeeKey,
  InventoryKey
];
