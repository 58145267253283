import React, {
  lazy,
  Suspense,
  memo,
  useEffect,
  createContext,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/shared-components/Loading";
import Views from "views";
import { Navigate,useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH, NO_PACKAGE_ENTRY } from "configs/AppConfig";
import UserService from "services/UserService";
import { updateUserDetails } from "store/slices/authSlice";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layouts = () => {
  const token = useSelector((state) => state.auth.token);
  const blankLayout = useSelector((state) => state.theme.blankLayout);
  const direction = useSelector((state) => state.theme.direction);
  const navType = useSelector((state) => state.theme.navType);
  const navCollapsed = useSelector((state) => state.theme.navCollapsed);

  const Layout = token && !blankLayout ? AppLayout : AuthLayout;

  const { user: vendorCategories } = useSelector((state) => state.auth);

  const navigate = useNavigate()

  const dispatch = useDispatch();

  const fetchProfile = async () => {
    const response = await UserService.getProfile();
    const data = response.data;
    console.log("profile details", data);
    if (data?.accountDetails && data?.GST) {
      dispatch(
        updateUserDetails({
          accountDetails: data.accountDetails,
          gstDetails: data.GST,
          userName: data?.username,
          name: data?.name,
          email: data?.email,
          phoneNo: data?.phoneNo,
          userId: data?._id
        })
      );
    }
    if(data?.vendorCategories?.length == 0){
      console.log('array is empty');
      // return <Navigate to={NO_PACKAGE_ENTRY} /> 
      // navigate(NO_PACKAGE_ENTRY);
      
    }
  };

  useEffect(() => {
    if(token){
    fetchProfile();
    }
  }, [token]);

  // export const initialState = {
  //   loading: false,
  //   message: "",
  //   showMessage: false,
  //   redirect: "",
  //   userId: localStorage.getItem(AUTH_ID) || null,
  //   userName: localStorage.getItem(AUTH_NAME) || null,
  //   token: localStorage.getItem(AUTH_TOKEN) || null,
  //   type: localStorage.getItem(AUTH_TYPE) || "",
  //   roles: JSON.parse(localStorage.getItem(AUTH_ROLES)) || {},
  //   user: JSON.parse(localStorage.getItem(AUTH_USER)) || {},
  //   userDetails: JSON.parse(localStorage.getItem(AUTH_USER_DETAILS)) || {},
  //   accountDetails: JSON.parse(localStorage.getItem(AUTH_ACCOUNT_DETAILS)) || {},
  //   gstDetails: JSON.parse(localStorage.getItem(AUTH_GST_DETAILS)) || {},
  // };

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Layout
        direction={direction}
        navType={navType}
        navCollapsed={navCollapsed}
      >
        <Views />
      </Layout>
    </Suspense>
  );
};

export default memo(Layouts);
