import React, { useEffect } from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";
import { AUTHENTICATED_ENTRY, NO_PACKAGE_ENTRY, REDIRECT_COMPONENT_KEY } from "configs/AppConfig";
import {
  protectedRoutes,
  publicRoutes,
  thirdPartyRoutes,
} from "configs/RoutesConfig";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppRoute from "./AppRoute";
import { useSelector } from "react-redux";
import {
  ADMIN_TYPE,
  AUTHENTICATED_CLIENT_ENTRIES,
  AUTHENTICATED_CLIENT_EXECUTIVE_ENTRIES,
  AUTHENTICATED_CUSTOMER_ENTRIES,
  AUTHENTICATED_THIRD_PARTY_ENTRIES,
  CLIENT_EXECUTIVE_TYPE,
  CLIENT_TYPE,
  CUSTOMER_TYPE,
  EMPLOYEE_TYPE,
  THIRD_PARTY_EMPLOYEE_TYPE,
  THIRD_PARTY_TYPE,
  VENDOR_TYPE,
} from "constants/AuthConstant";

const Routes = () => {
  const { type, roles } = useSelector((state) => state.auth);
  const { user: vendorCategories } = useSelector((state) => state.auth);


  // if (vendorCategories?.length === 0) {
  //   return <Navigate replace to={NO_PACKAGE_ENTRY} />;
  // }

  

  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route
          path='*'
          element={<Navigate replace to={AUTHENTICATED_ENTRY} />}
        />
        {/* <Route
          path="*"
          element={
            vendorCategories?.length==0 ? (
              <Navigate replace to={NO_PACKAGE_ENTRY} /> 
            ) : (
              <Navigate replace to={AUTHENTICATED_ENTRY} />
            )
          }
        /> */}
        {protectedRoutes.map((route, index) => {
          if (
            route.isPage ||
            type === VENDOR_TYPE ||
            route.key === REDIRECT_COMPONENT_KEY
          ) {
            return (
              <Route
                key={route.key + index}
                path={route.path}
                element={
                  <AppRoute
                    routeKey={route.key}
                    component={route.component}
                    {...route.meta}
                  />
                }
              />
            );
          }
        })}

        {/* {type === THIRD_PARTY_TYPE &&
          thirdPartyRoutes.map((route, index) => (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          ))} */}
        {/* <Route path="*" element={<Navigate to={'/'} replace />} /> */}
      </Route>
      <Route path="/" element={<PublicRoute />}>
      {/* <Route
          path='*'
          element={<Navigate replace to={NO_PACKAGE_ENTRY} />}
        /> */}
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  blankLayout={!!route.blankLayout}
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
