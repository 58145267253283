import React from 'react';
import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  REDIRECT_COMPONENT_KEY
} from 'configs/AppConfig';
import * as routeKeys from 'constants/RoutesKeys';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('views/auth-views/authentication/login'))
  },
  {
    key: 'register',
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() => import('views/auth-views/authentication/register'))
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/forgot-password')
    )
  },
];

export const protectedRoutes = [
  {
    key: 'pages',
    isPage: true,
    path: `${APP_PREFIX_PATH}/pages`,
    component: React.lazy(() => import('views/app-views/pages'))
  },
  {
    key: 'pages.profile',
    isPage: true,
    path: `${APP_PREFIX_PATH}/pages/profile`,
    component: React.lazy(() => import('views/app-views/pages/profile'))
  },
  // {
  //   key: routeKeys.SettingsKey,
  //   path: `${APP_PREFIX_PATH}/dashboards/settings-list/*`,
  //   component: React.lazy(() => import('views/app-views/dashboards/settings'))
  // },
  {
    key: routeKeys.ListingKey,
    path: `${APP_PREFIX_PATH}/dashboards/listing-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/listing')
    )
  },
  {
    key: routeKeys.bookingKey,
    path: `${APP_PREFIX_PATH}/dashboards/booking-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/booking')
    )
  },
  {
    key: routeKeys.transactionsKey,
    path: `${APP_PREFIX_PATH}/dashboards/transactions-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/transactions')
    )
  },
  {
    key: routeKeys.receiptsKey,
    path: `${APP_PREFIX_PATH}/dashboards/receipts-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/receipt-list')
    )
  },
  {
    key: routeKeys.cartKey,
    path: `${APP_PREFIX_PATH}/dashboards/cart-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/cart')
    )
  },
  {
    key: routeKeys.enquiryKey,
    path: `${APP_PREFIX_PATH}/dashboards/enquiry-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/enquiry')
    )
  },
  {
    key: routeKeys.calendarKey,
    path: `${APP_PREFIX_PATH}/dashboards/booking-calendar/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/booking-calendar')
    )
  },
  {
    key: routeKeys.ratingsKey,
    path: `${APP_PREFIX_PATH}/dashboards/ratings-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/rating')
    )
  },
  {
    key: routeKeys.vendorCartKey,
    path: `${APP_PREFIX_PATH}/dashboards/vendor-cart-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/vendor-cart')
    )
  },
  {
    key: routeKeys.expensesKey,
    path: `${APP_PREFIX_PATH}/dashboards/expenses-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/expenses')
    )
  },
  {
    key: routeKeys.subscriptionsKey,
    path: `${APP_PREFIX_PATH}/dashboards/vendor-subscriptions-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/vendor-subscriptions')
    )
  },
  {
    key: routeKeys.vendorPaymentsKey,
    path: `${APP_PREFIX_PATH}/dashboards/vendor-payments-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/vendor-payments')
    )
  },
  {
    key: routeKeys.bookingDateChangeKey,
    path: `${APP_PREFIX_PATH}/dashboards/booking-date-change-request-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/booking-date-change')
    )
  },
];
